import es_logo from './../img/cut_logo.png';
import es_logo_text from './../img/cut_logo_text.png';

const Home = () => {
	return (
		<>
			{/* <Header /> */}
			<main className="section">
				<div className="container">
					<picture>
						<source media="(max-width: 620px)" srcSet={es_logo_text}/>
						<img src={es_logo}/>
					</picture>
				</div>
			</main>
		</>
	);
}

export default Home;