const Contacts = () => {
	return (
		<main className="section">
			<div className="container">
				<h1 className="title-contact">
					<strong>
						Контакты
					</strong>
				</h1>
				<ul className="content-list">
					{/* <li className="content-list__item">
						<h2 className="title-2">Адрес</h2>
						<p>Moscow, Russia</p>
					</li> */}
					<li className="content-list__item">
						<h2 className="title-2">Telegram / WhatsApp</h2>
						<p>
							<a href="tel:+998 90 984 07 30">+998 90 984 07 30</a>
						</p>
					</li>
					<li className="content-list__item">
						<h2 className="title-2">Email</h2>
						<p>
							<a href="mailto:GlobusEstet@gmail.com">
								GlobusEstet@gmail.com
							</a>
						</p>
					</li>
				</ul>
			</div>
		</main>
	);
}

export default Contacts;